import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {Injectable, Injector} from "@angular/core";
import {ApiService} from "../services/api-service/api.service";

@Injectable()
export class UnleashInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isNeedToIntercept(req.url)) {
      return next.handle(req.clone());
    }

    let headers: { [name: string]: string | string[] } = {};
    headers = this.addAuthHeader(req.url, headers);
    const system: string | undefined = this.injector.get(ApiService).auth.system;
    return next.handle(req.clone({url: `${req.url}?userId=${system}`, setHeaders: headers}));
  }

  private addAuthHeader(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    headers = this.append(headers, 'Authorization', environment.unleashToken);
    return headers;
  }

  private append(obj: { [name: string]: string | string[] }, key: string, value: string): {
    [name: string]: string | string[]
  } {
    if (key in obj) {
      const existingValue: string | string[] = obj[key];
      if (Array.isArray(existingValue)) {
        existingValue.push(value);
      } else {
        obj[key] = [existingValue, value];
      }
    } else {
      obj[key] = value;
    }
    return obj;
  }

  private isNeedToIntercept(url: string): boolean {
    return (url.includes(environment.unleashUrl));
  }
}
