import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {catchError, from, Observable, of, switchMap, throwError} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {set$notifyIOBStatusVersionError} from "../../shared/util/FallbackNotifiers";
import {environment} from "../../../environments/environment";
import {NodeConfiguration} from "../interfaces/nodeConfiguration";
import {ApiService} from "../services/api-service/api.service";
import {CookieService} from "../services/browser-helper-service/cookie.service";
import {LocalStorageSchemaService} from "../services/browser-helper-service/local-storage-schema.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  toast = inject(ToastService);

  constructor(private apiService: ApiService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.includes('settings/features.json') || req.url.includes(environment.unleashUrl)){
      return next.handle(req)
    }
    return next.handle(req).pipe(
      catchError(request => {
        if (req.url?.includes('CESAdmin') && req.url?.includes('/image') && [404].includes(request.status)) {
          return of(new HttpResponse(request));
        }
        if ((req.url?.includes('CESAdmin') || req.url?.includes('CESMaster') && !req.url?.endsWith('/image'))) {
          const errorText: string | undefined = this.errorText(request);
          this.toast.showError(errorText);
        }
        if (req.url?.includes('CESAdmin') && [401, 403].includes(request.status)) {
          return from(this.apiService.tokenService.reLogin()).pipe(
            switchMap(valid => {
              if (valid) {
                let headers: { [name: string]: string | string[] } = {};
                headers = this.addApiKeyDataNode(req.url, headers);
                headers = this.addHeadersDataNode(req.url, headers);
                return next.handle(req.clone({setHeaders: headers}));
              } else {
                return throwError(() => '401, 403 - not recoverable');
              }
            })
          );
        }
        if (req.url?.includes('status') && [400].includes(request.status)) {
          if (request.error && typeof request.error =='string' && request.error.includes('version is not valid')) {
            set$notifyIOBStatusVersionError(true);
          }
        }
        return of(new HttpResponse(request));
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    )
  }

  private errorText(request: any): string {
    let message: string | undefined;
    if (request && request.error) {
      if (request.error.raw && !request.error.raw.includes(' ')) {
        message = request.error.raw;
      } else if (request.error.message && !request.error.message.includes(' ')) {
        message = request.error.message;
      } else if (request.error.status && !request.error.status.includes(' ')) {
        message = request.error.status;
      }
    }
    return message ? `NOTIFICATION.TOAST.CES_ERROR.${message}` : 'NOTIFICATION.TOAST.ERROR.DEFAULT';
  }

  private messageSwitch(msg: string): string {
    switch (msg) {
      case 'cesErrForbidden':
        return msg
    }
    return msg;
  }

  private addApiKeyDataNode(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    if (url.includes('CESAdmin') || (url.includes('/status') && url.includes(environment.ioBridgeUrl))) {
      const localDataNode: NodeConfiguration | undefined = this.apiService.nodeService.nodeConfiguration;
      if (localDataNode != undefined) {
        headers = this.append(headers, 'API-Key', `${localDataNode.apiKey}`);
      }
    }
    return headers;
  }

  private append(obj: { [name: string]: string | string[] }, key: string, value: string): {
    [name: string]: string | string[]
  } {
    if (key in obj) {
      const existingValue: string | string[] = obj[key];
      if (Array.isArray(existingValue)) {
        existingValue.push(value);
      } else {
        obj[key] = [existingValue, value];
      }
    } else {
      obj[key] = value;
    }
    return obj;
  }

  private addHeadersDataNode(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    if (url.includes('CESAdmin')) {
      if (!url.includes('/image')) {
        //headers = this.append(headers, 'Content-Type:', 'application/json');
      }
      const token: string | null = this.apiService.cookieService.getCookie(CookieService.TOKEN);
      if (token) {
        headers = this.append(headers, 'Authorization', `Bearer ${token}`);
        if (url.includes('refreshToken')) {
          headers = this.append(headers, 'New-Token', `${token}`);
          headers = this.append(headers, 'New-Expire-At', `${Date.parse(this.apiService.cookieService.getCookie(CookieService.EXPIRE_AT) || 'Thu, 01 Jan 1970 00:00:00 UTC')}`);
        }
      }
      headers = this.append(headers, 'Localization', `${new Map([
        [ 'de', 'de-DE' ],
        [ 'en', 'en-GB' ],
        [ 'nl', 'nl-NL' ],
        [ 'fr', 'fr-FR' ]
      ]).get(new LocalStorageSchemaService().fromLocalStorage(LocalStorageSchemaService.language).plain || 'en') || 'en-GB'}`);
    }
    return headers;
  }

}
