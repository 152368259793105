import {CanActivate, CanDeactivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {ChangePasswordComponent} from "../../layouts/topbar/modals/change-password/change-password.component";
import {ApiService} from "../services/api-service/api.service";

@Injectable({
  providedIn: 'root'
})
export class ForcePasswordChangeGuard implements CanActivate, CanDeactivate<ChangePasswordComponent> {

  constructor(
    private router: Router,
    private apiService: ApiService,
  ) {
  }

  canActivate(): boolean {
    if (this.apiService.auth.session?.changePassword == true) {
      this.router.navigate(["/user/new-password"]);
    }
    return this.apiService.auth.session?.changePassword != true;
  }

  canDeactivate(): boolean {
    return this.apiService.auth.session?.changePassword != true;
  }
}
