import {Component} from "@angular/core";
import {LanguageService} from "./core/services/language-service/language.service";
import {ApiService} from "./core/services/api-service/api.service";
import {UnleashService} from "./core/services/unleash-service/unleash.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  title = 'adminAngular';
  defaultLang = 'de';

  // Reference languageService to be initialized
  constructor(
    language: LanguageService,
    private apiService: ApiService,
    private unleash: UnleashService
  ) {
    language.setDefaultLanguage(this.defaultLang);
    this.apiService.tokenService.loginChecker();
    this.unleash.featureFlagsRecursiveUpdater(true);
  }
}
