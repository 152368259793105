import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {ClientDto} from "../../shared/entities/client/ClientDto";
import {ApiService} from "../services/api-service/api.service";
import {UserSessionInfoDto} from "../../shared/entities/user/UserSessionInfoDto";
import {UserLoginResponseDto} from "../../shared/entities/user/UserLoginResponseDto";
import {Logger} from "../../shared/util/logger";

@Injectable()
export class ProviderInterceptor implements HttpInterceptor {

  constructor(private apiService: ApiService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (req.url.endsWith('/client') && (req.method === 'PATCH' || req.method === 'GET')) {
            const clientDto = event.body as ClientDto;
            this.apiService.setClient(clientDto);
          }
          if (req.url.endsWith('/auth/login') && req.method === 'POST') {
            const sessionDto: UserSessionInfoDto = UserSessionInfoDto.FROM_UserLoginResponseDto(event.body as UserLoginResponseDto);
            this.apiService.setSession(sessionDto);
          }
          if (req.url.endsWith('/auth/refreshToken') && req.method === 'GET') {
            if (event.headers.has('New-Token')) {
              const token: string = event.headers.get('New-Token')!;
              Logger.info(`New Token received: ${token}`);
              this.apiService.cookieService.setAuthCookie(token);
            }
          }
        }
      })
    );
  }
}
